import { faCopy, faEye, faMoneyBill, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ACTIVE } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { useCountdown } from 'helpers/hooks/useCountdown';
import { useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { MerchantTokenProps } from 'services/API/MerchantToken/interface';

interface ActionBoxProps {
  isOpen: boolean;
  selectedMerchantToken: null | MerchantTokenProps;
  onView: () => void;
  onCreateTransaction: () => void;
  onViewTransaction: () => void;
  reload: () => void;
}

interface RowProps {
  name: string;
  data: string;
}

export const ActionBox = ({
  isOpen,
  selectedMerchantToken,
  onView,
  onCreateTransaction,
  onViewTransaction,
  reload,
}: ActionBoxProps) => {
  const { translate } = useTranslate();
  const [countdown, setCountdown] = useCountdown();

  const Row = ({ name, data }: RowProps) => {
    return (
      <div className="row">
        <div className="col-6">{name}</div>
        <div className="col-6 colfax-regular">{data}</div>
      </div>
    );
  };

  const onGetLinkBtnClicked = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      setCountdown(5);
    });
  };

  const getLinks = (key: any) => {
    return selectedMerchantToken?._links?.[key] ?? '';
  };

  useEffect(() => {
    setCountdown(0);
  }, [selectedMerchantToken]);

  const attr: any = {};
  attr.custom_id = selectedMerchantToken?.custom_id ?? '';
  attr.store_name = selectedMerchantToken?.store?.name ?? '';

  return (
    <>
      <div className="bg-white mb-4 round-sm">
        <div className="filter-title">
          <span className="colfax-bold">{translate('actions')}</span>
        </div>
        <div className="py-1">
          <Collapse isOpen={isOpen}>
            <div className="px-3 py-3">
              <Row name={translate('custom_id')} data={attr?.custom_id ?? ''} />
              {attr?.store_name && (
                <Row name={translate('store_name')} data={attr?.store_name ?? ''} />
              )}
            </div>

            <div className="border-bottom" />
            <div
              className="d-flex flex-wrap justify-content-between px-3 py-3"
              style={{ rowGap: '5px', columnGap: '10px', width: '100%' }}>
              <button className="btn btn-primary px-2" onClick={() => onViewTransaction()}>
                <FontAwesomeIcon icon={faEye} />
                &nbsp;
                {translate('view_transaction')}
              </button>
              {/* <button className="btn btn-warning px-2" onClick={() => onView()}>
                <FontAwesomeIcon className={'pr-1'} icon={faPen} />
                &nbsp;
                {translate('edit')}
              </button> */}

              {/* {selectedMerchantToken?.status === ACTIVE && (
                <>
                  <button className="btn btn-primary px-2" onClick={() => onViewTransaction()}>
                    <FontAwesomeIcon icon={faEye} />
                    &nbsp;
                    {translate('view_transaction')}
                  </button>

                  <button className="btn btn-success px-2" onClick={() => onCreateTransaction()}>
                    <FontAwesomeIcon icon={faMoneyBill} />
                    &nbsp;
                    {translate('create_transaction')}
                  </button>
                </>
              )} */}
              {/* {selectedMerchantToken?._links && (
                <button
                  className="btn btn-primary"
                  onClick={() => onGetLinkBtnClicked(getLinks('register'))}>
                  <FontAwesomeIcon icon={faCopy} />
                  &nbsp;
                  {translate(countdown > 0 ? 'copied' : 'copy_link')}
                </button>
              )} */}
            </div>
            {/* </div> */}
          </Collapse>
        </div>
      </div>
    </>
  );
};
