import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, GlobalTypes, ThunkResult } from 'redux/types';
import {
  getCompanyBalanceTransaction,
  updateBalance as apiUpdateBalance,
} from 'services/API/Balance';
import { AnyResponse } from 'services/API/common/Fetcher';
import { UPDATE_BALANCE } from './actionTypes';
import { receiveData } from './DataAction';
import { getCompanyTransactionList } from './TransactionActions';

export const getBalanceList = (
  company_id: string,
  data: object,
): ThunkResult<Promise<Record<string, any>>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await getCompanyBalanceTransaction(company_id, data);
    if (res.success) {
      dispatch(receiveData(res, 'balanceTransactions'));
    }
    return res;
  });

export const updateBalance = (): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch, getState: () => GlobalTypes.RootState) => {
    const user = getState().auth.user;
    const res = await apiUpdateBalance();

    if (res?.success) {
      dispatch({
        type: UPDATE_BALANCE,
        payload: res.data,
      });

      if (user.role === 'operator') {
        dispatch(getCompanyTransactionList(user.company_id));
      }
    }

    return res;
  });
