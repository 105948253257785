import { useLocation } from 'react-router-dom';
import { getCompanyAnalysisAction, getCompanyAnalysisV2Action } from 'actions/SalesAnalysisActions';
import { getStoresOptions } from 'actions/StoreActions';
import { BalanceBox } from 'components/custom/BalanceBox';
import { DateRangeDropdown } from 'components/custom/DateRangeDropdown';
import { Input } from 'components/custom/input';
import { ReportSection } from 'components/custom/ReportSection';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { DATE_RANGE_OPTIONS } from 'constants/format';
import {
  ALLOW_CREATE_SETTLEMENTS_ROLES,
  convertSaleAnalysisGroupToOptions,
  SALES_ANALYSIS_GROUP,
} from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import moment from 'moment';
import { isEmpty, isNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanySalesAnalysis, getCompanySalesAnalysisV2 } from 'services/API/Sales_Analysis';
import onNotification from 'services/firebase';
import { useSwal } from 'helpers/sweetalert';
import { TransactionModal } from './createTransaction';
import './summary.scss';
import { WithdrawalModal } from './withdrawal';
import { updateBalance } from 'actions/BalanceActions';
import { DailyBalanceProps } from 'services/API/Balance/interface';

// * ALICE-665: stop refetching data if it fails 3 times
const THRESHOLD_FETCH_NO_PROGRESS_COUNT = 3;

interface RecordProps {
  title: string;
  amount: string | number;
  currency: string;
}

export const AccountSummary = () => {
  const {
    auth: { credentials, user, roles },
    data: {
      analysis: {
        // ALICE-659: endpoint data is now async
        sale: { data, pagination, group_by: saleGroupBy, progress },
      },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const dispatch = useDispatch<FunctionDispatch>();

  const location = useLocation();
  const isDebugEnabled = new URLSearchParams(location.search).get('debug') === '1';
  const { translate } = useTranslate();
  const [Swal] = useSwal();

  const reportSectionRef = useRef<any>(null);
  const [isExportDropdownLoading, setIsExportDropdownLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [openExportDropdown, setOpenExportDropdown] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);
  const [dailyBalance, setDailyBalance] = useState<null | DailyBalanceProps>(null);
  const [failCount, setFailCount] = useState(0);
  const [filter, setFilter] = useState({
    group_by: '',
    date_range: DATE_RANGE_OPTIONS[0].value,
    start_date: null,
    end_date: null,
  });
  const { group_by, date_range, start_date, end_date } = filter;

  const getDataEnabled = useMemo(() => {
    return Boolean(group_by && (start_date || end_date || date_range));
  }, [group_by, start_date, end_date, date_range]);

  const getData = useCallback(
    async (page = 1) => {
      if (!user || (!date_range && !start_date && !end_date)) {
        return;
      }

      if (group_by === '') {
        return;
      }

      const params: any = {
        group_by,
        page,
      };

      /* *** important ***
      for future use, if change to use v2, should add include_topup = 1
    */
      params.include_topup = 1;

      if (start_date && end_date) {
        params.start_date = start_date;
        params.end_date = end_date;
      } else {
        params.start_date = moment().add(date_range).format('YYYY-MM-DD');
        params.end_date = moment().add(date_range).format('YYYY-MM-DD');
      }

      setIsPageLoading(true);
      const res = await dispatch(
        // * ALICE-659: include legacy endpoint for testing
        isDebugEnabled
          ? getCompanyAnalysisAction(user.company_id, params)
          : getCompanyAnalysisV2Action(user.company_id, params),
      );

      setIsPageLoading(false);
      if (!res.success) {
        return Swal.fire({
          icon: 'error',
          title: translate('transaction'),
          text: res.message,
        });
      }

      // * ALICE-665: getMe only if fetch data success
      if (res?.data) {
        dispatch(updateBalance()).then((res) => {
          if (res.success) {
            if (res.data && res.data.company && res.data.company.daily_balance) {
              setDailyBalance(res.data.company.daily_balance.data);
            }
          }
        });
      }
    },
    [filter],
  );

  const exportReport = async (type: string) => {
    setIsExportDropdownLoading(true);
    const params: any = {
      group_by,
    };

    if (start_date && end_date) {
      params.start_date = start_date;
      params.end_date = end_date;
    } else {
      params.start_date = moment().add(date_range).format('YYYY-MM-DD');
    }
    const totalDays = Math.abs(moment(params.start_date).diff(params.end_date, 'days'));
    if (type === 'pdf') {
      params.include = 'transaction_list';
      if (totalDays > 31) {
        Swal.fire({
          icon: 'error',
          title: translate('export'),
          text: translate('error_export_pdf_duration_must_be_less_equal_than_31_days'),
        });
        setIsExportDropdownLoading(false);
        return;
      }
    } else {
      const dayLimits = 97;
      if (totalDays > dayLimits) {
        Swal.fire({
          icon: 'error',
          title: translate('export'),
          text: translate('error_duration_must_be_less_equal_than', [
            dayLimits.toString(),
            translate('unit_day'),
          ]),
        });
        setIsExportDropdownLoading(false);
        return;
      }
    }
    params.asyncExport = true;

    /* *** important ***
      for future use, if change to use v2, should add include_topup = 1
    */
    params.include_topup = 1;

    const res = isDebugEnabled
      ? // * ALICE-659: include legacy endpoint for testing
        await getCompanySalesAnalysis(user.company_id, {
          ...params,
          export: type,
        })
      : await getCompanySalesAnalysisV2(user.company_id, {
          ...params,
          export: type,
        });
    // * ALICE-655: wait for ONE second since the export is async
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await reportSectionRef?.current?.loadReports();

    setIsExportDropdownLoading(false);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('export'),
        text: res.message || 'Oops',
      });
      return;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (openTransactionModal && user?.stores?.length === 0) {
        const res = await dispatch(getStoresOptions(user?.company_id));
        if (!res.success) {
          return Swal.fire({
            icon: 'error',
            title: translate('store_list'),
            text: res.message,
          });
        }
      }
    };
    fetch();
    // eslint-disable-next-line
  }, [openTransactionModal]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isPageLoading || !isNumber(progress)) {
        return;
      }
      if (progress <= 1 && failCount < THRESHOLD_FETCH_NO_PROGRESS_COUNT) {
        setFailCount((prev) => prev + 1);
        getData();
      }
    }, 3000); // Adjust the interval time as needed

    return () => {
      clearInterval(intervalId);
    };
  }, [isPageLoading, progress, getData]);

  const onFilterSelectChange = (name: keyof typeof filter) => (value: any) => {
    if (name === 'date_range') {
      setFilter({
        ...filter,
        date_range: value,
        start_date: value.start_date,
        end_date: value.end_date,
      });
      return;
    }

    setFilter({
      ...filter,
      [name]: value,
    });
  };

  useEffect(() => onNotification(getData), [getData]);

  const detailHeader = () => {
    let name;

    switch (group_by) {
      case 'store':
        name = translate('store_name');
        break;
      case 'gateway':
        name = translate('gateway_name');
        break;
      default:
    }

    return (
      <tr>
        <th className="border-end border-top-0">{name}</th>
        <th className="border-end border-top-0">{translate('total_paid')}</th>
        <th className="border-end border-top-0">{translate('total_paid_amount')}</th>
        <th className="border-end border-top-0">{translate('total_charge')}</th>
        <th className="border-end border-top-0">{translate('total_refund')}</th>
        <th className="border-end border-top-0">{translate('total_refund_amount')}</th>
        <th className="border-end border-top-0">{translate('total_cancel')}</th>
        <th className="border-end border-top-0">{translate('total_transactions')}</th>
        <th className="border-top-0">{translate('average_ticket')}</th>
      </tr>
    );
  };

  const detailBody = () => {
    let name_field: string;

    switch (saleGroupBy) {
      case 'store':
        name_field = 'store_name';
        break;
      case 'gateway':
        name_field = 'gateway_name';
        break;
      default:
    }

    return data
      ? data.map((sale, index) => {
          const {
            total_paid,
            total_paid_amount,
            total_charge,
            total_refund,
            total_refund_amount,
            total_cancel,
            total_transactions,
            average_ticket,
          } = sale;

          return (
            <tr key={`account-summary-body-${index}`}>
              <td>{sale[name_field]}</td>
              <td>{total_paid}</td>
              <td>{total_paid_amount}</td>
              <td>{total_charge}</td>
              <td>{total_refund}</td>
              <td>{total_refund_amount}</td>
              <td>{total_cancel}</td>
              <td>{total_transactions}</td>
              <td>{average_ticket}</td>
            </tr>
          );
        })
      : [];
  };

  // * ALICE-665: reset fail count on unmounting
  useEffect(() => {
    return () => {
      setFailCount(0);
    };
  }, []);

  return (
    <>
      <TopHeader title={translate('account_summary')}>
        {ALLOW_CREATE_SETTLEMENTS_ROLES.includes(user.role) && (
          <>
            <button
              className="header-button btn btn-success"
              onClick={() => setOpenTransactionModal(true)}
              disabled={isPageLoading}>
              {translate('create_transaction')}
            </button>
            {roles.isOwner && (
              <>
                &nbsp;
                <Dropdown
                  disabled={isExportDropdownLoading || isPageLoading}
                  isOpen={openExportDropdown}
                  toggle={() => setOpenExportDropdown(!openExportDropdown)}
                  className="d-inline-block"
                  style={{ opacity: isPageLoading ? 0.65 : 1 }}>
                  <DropdownToggle
                    disabled={!getDataEnabled || isExportDropdownLoading || isPageLoading}
                    className="header-button btn btn-success"
                    caret
                    color="#ffffff">
                    {isExportDropdownLoading ? <Spinner size="sm" /> : translate('export')}
                  </DropdownToggle>
                  <DropdownMenu classnames="lang-dropdown">
                    <DropdownItem
                      classnames="btn btn-sm btn-muted text-center"
                      onClick={() => exportReport('xlsx')}>
                      {translate('generate_excel_report')}
                    </DropdownItem>
                    <DropdownItem
                      classnames="btn btn-sm btn-muted text-center"
                      onClick={() => exportReport('csv')}>
                      {translate('generate_csv_report')}
                    </DropdownItem>
                    <DropdownItem
                      classnames="btn btn-sm btn-muted text-center"
                      onClick={() => exportReport('pdf')}>
                      {translate('generate_pdf_report')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                &nbsp;
                <button
                  className="header-button btn btn-primary"
                  onClick={() => setOpenWithdrawalModal(true)}
                  disabled={isPageLoading}>
                  {translate('withdrawal')}
                </button>
                <WithdrawalModal
                  isOpenModal={openWithdrawalModal}
                  setIsOpenModal={setOpenWithdrawalModal}
                />
              </>
            )}

            <TransactionModal
              credentials={credentials}
              isTransactionModalOpen={openTransactionModal}
              onHide={() => setOpenTransactionModal(false)}
              onCompleteCreate={getData}
            />
          </>
        )}
        {roles.isAccountant && (
          <Dropdown
            disabled={isExportDropdownLoading}
            isOpen={openExportDropdown}
            toggle={() => setOpenExportDropdown(!openExportDropdown)}
            className="d-inline-block">
            <DropdownToggle
              disabled={isExportDropdownLoading}
              className="header-button btn btn-success"
              caret
              color="#ffffff">
              {isExportDropdownLoading ? <Spinner size="sm" /> : translate('export')}
            </DropdownToggle>
            <DropdownMenu classnames="lang-dropdown">
              <DropdownItem
                classnames="btn btn-sm btn-muted text-center"
                onClick={() => exportReport('xlsx')}>
                {translate('generate_excel_report')}
              </DropdownItem>
              <DropdownItem
                classnames="btn btn-sm btn-muted text-center"
                onClick={() => exportReport('pdf')}>
                {translate('generate_pdf_report')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </TopHeader>
      <div className="searchBar d-flex flex-column">
        <div className="d-flex flex-column flex-lg-row" style={{ width: '100%' }}>
          <div style={{ flex: '2 1', paddingRight: '1rem' }}>
            <Input
              type="react-select"
              legend=""
              options={convertSaleAnalysisGroupToOptions(
                SALES_ANALYSIS_GROUP[user.role],
                translate,
              )}
              value={group_by}
              name="group_by"
              isSearchable={false}
              onChange={onFilterSelectChange('group_by')}
              menuPortalTarget={document.body}
              disabled={isPageLoading}
              placeholder={translate('select_group_by_option')}
            />
          </div>
          <div style={{ width: '330px' }}>
            <DateRangeDropdown
              value={date_range}
              onChange={onFilterSelectChange('date_range')}
              isDisabled={isPageLoading}
            />
          </div>
          <div
            style={{
              height: '55.59px',
            }}>
            <button
              className="btn btn-primary"
              style={{ height: '38.75px' }}
              onClick={getData}
              disabled={isPageLoading || !getDataEnabled}>
              {translate('apply')}
            </button>
          </div>
        </div>
        <i
          style={{
            fontSize: '10px',
          }}>
          {translate('sales_analysis_data_refresh_notice')}
        </i>
      </div>
      <div id="main-content" className="hide-scrollbar" style={{ height: 'calc(100vh - 12rem)' }}>
        <CustomTable
          totalColumn={10}
          renderHeading={detailHeader}
          pagination={isPageLoading || isEmpty(pagination) ? null : pagination}
          setCurrentPage={getData}
          renderData={detailBody}
          isLoading={isPageLoading || isNumber(progress)}
        />
      </div>
      <div id="side-content" className="hide-scrollbar" style={{ height: 'calc(100vh - 12rem)' }}>
        <ReportSection ref={reportSectionRef} type="sales-analysis" />
        <BalanceBox />
        <RecordBox
          title={translate('today_sales')}
          amount={dailyBalance?.total_float ?? '-'}
          currency={'HKD'}
        />
        <RecordBox
          title={translate('today_net')}
          amount={dailyBalance?.amount_float ?? '-'}
          currency={'HKD'}
        />
      </div>
    </>
  );
};

const RecordBox = ({ title, amount, currency }: RecordProps) => {
  return (
    <div className="bg-white mb-4 round-sm shadow-sm">
      <div className="px-4 py-3 border-bottom">{title}</div>
      <div className="px-4 py-3">
        <span className="h3 me-3 amount">{amount}&nbsp;</span>
        <span className="">{currency}</span>
      </div>
    </div>
  );
};
