import { EmailFormProps, PhoneFormProps } from 'components/Auth/register/interface';
import axios, {
  AnyResponse,
  defaultRequestHandler,
  DefaultResponse,
  getToken,
  loginRequestHandler,
  params,
} from '../common/Fetcher';
import { ContentType } from '../common/Headers';
import {
  ActivateOwnerProps,
  ActivationForm,
  ForceResetPasswordResponse,
  GetActivationCodeBody,
  LoginBodyProps,
  RegisteredReturn,
  RegisterServiceProviderProps,
  RequestResetPasswordProps,
  RequestFirstSetPasswordProps,
  RequestResetPasswordResponse,
  RequestFirstSetPasswordResponse,
  ResetPasswordProps,
  FirstSetPasswordProps,
  ResetPasswordResponse,
  FirstSetPasswordResponse,
  UpdatePasswordBody,
} from './interface';
// @todo add response type
// https://dev2.yedpay.com/document/admin/login
export const login = (body: LoginBodyProps) =>
  loginRequestHandler(
    axios.post(`/token`, params(body), {
      headers: {
        accept: ContentType.json,
        'Content-Type': ContentType.urlEncoded,
        'Access-Control-Allow-Origin': '*',
      },
    }),
  );

// This endpoint doesn't return success and data field
// https://dev2.yedpay.com/document/admin/ownerRegistration
export const register = (
  data: EmailFormProps | PhoneFormProps,
): Promise<RegisteredReturn | DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/user-registration`, data, {
      headers: {
        'Content-Type': ContentType.json,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/sendActivationCode
export const getActivationCode = (body: GetActivationCodeBody): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/user-activations`, body, {
      headers: {
        'Content-Type': ContentType.json,
      },
    }),
  );

export const getMe = (): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.get(
      '/me?include=company,stores,balance,daily_balance,company_balance,companies,documents,gateways',
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );

// @todo add response type
// https://dev2.yedpay.com/document/admin/activateOwner
export const activateOwnerWithZipFile = (body: ActivateOwnerProps): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/me/activate`, body, {
      timeout: 60 * 1000,
      headers: {
        authorization: getToken(),
      },
    }),
  );

// @todo add response type
// https://dev2.yedpay.com/document/admin/activationForm
export const activateAccount = (body: ActivationForm): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.post(`/activation-form`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

// @todo add response type
// https://dev2.yedpay.com/document/admin/updatePassword
export const forceUpdatePassword = (
  user_id: string,
  token: string,
  body: UpdatePasswordBody,
): Promise<ForceResetPasswordResponse> =>
  defaultRequestHandler(
    axios.post(`/users/${user_id}/password-update/${token}`, body, {
      headers: {
        'Content-Type': ContentType.json,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/resetPassword
export const resetPassword = (body: ResetPasswordProps): Promise<ResetPasswordResponse> =>
  defaultRequestHandler(
    axios.post(`/password/reset`, params(body), {
      headers: {
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

// https://dev2.yedpay.com/document/admin/requestResetPassword
export const requestResetPassword = (
  body: RequestResetPasswordProps,
): Promise<RequestResetPasswordResponse> =>
  defaultRequestHandler(
    axios.post(`/password/request`, body, {
      headers: {
        'Content-Type': ContentType.json,
      },
    }),
  );

export const firstSetPassword = (body: FirstSetPasswordProps): Promise<FirstSetPasswordResponse> =>
  defaultRequestHandler(
    axios.post(`/setup-password/reset`, params(body), {
      headers: {
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

export const requestFirstSetPassword = (
  body: RequestFirstSetPasswordProps,
): Promise<RequestFirstSetPasswordResponse> =>
  defaultRequestHandler(
    axios.post(`/setup-password/request`, body, {
      headers: {
        'Content-Type': ContentType.json,
      },
    }),
  );

export const registerServiceProvider = (body: RegisterServiceProviderProps): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.post(`/service-provider-register`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );
