import { SelectOptionProps } from 'components/custom/input/interfaces/index';

/**
 * https://tools.ietf.org/html/rfc1738
 *
 * hostname       = *[ domainlabel "." ] toplabel
 * domainlabel    = alphadigit | alphadigit *[ alphadigit | "-" ] alphadigit
 * toplabel       = alpha | alpha *[ alphadigit | "-" ] alphadigit
 *
 * Match: example.com, *.example.com
 */
const toplabel = '([a-zA-Z]|([a-zA-Z][a-zA-Z0-9-]*)[a-zA-Z0-9])';
const domainlabel = '([a-zA-Z0-9]|([a-zA-Z0-9]([a-zA-Z0-9-]*)[a-zA-Z0-9]))';
const hostname = `(\\*\\.)?(${domainlabel}\\.)+${toplabel}`;
export const DOMAIN_REGEX = new RegExp(`^${hostname}$`);

// eslint-disable-next-line
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const FIELD_REQUIRED = 'This field is required';

export const HAS_NUMBER_REGEX = /\d/;
export const NUMBER_REGEX = /^\d+$/;
export const DECIMAL_REGEX = /^\d+\.\d{0,2}$/;

export const TIME_ZONE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const REGISTRATION_STEPS = 3;
export const REGISTRATION_STEPS_TAXI = 2; // skip store setup

export const OFFLINE_STORE = 1;
export const ONLINE_STORE = 2;

export const OFFLINE_GATEWAY_AGREEMENT = 'offline_gateway_agreement';
export const ONLINE_GATEWAY_AGREEMENT = 'online_gateway_agreement';
export const OTHER_AGREEMENT = 'other_agreement';

export const OTHER_CONTRACT = 'OTHER_CONTRACT';
export const YEDPAY_CONTRACT = 'YEDPAY_CONTRACT';

export const WEB = 1;
export const APP = 2;

export const HTTP = 'http';
export const HTTPS = 'https';

export const HK = 'HK';
export const CN = 'CN';
export const HKCN = 'HKCN';
export const OVERSEAS = 'OVERSEAS';

export const ACTIVE = 'active';
export const INACTIVE = 'inactive';

export const YEDPAY_ADDRESS = `33, Unit 2301-02, Port, 33 Tseuk Luk St, San Po Kong`;

export interface ANY_OBJECT {
  [key: string]: any;
}

export const translateLabel = (
  options: Array<SelectOptionProps>,
  translate: (id: string) => string,
  useLabel = true,
): Array<SelectOptionProps> => {
  return options.map((option) => ({
    ...option,
    label: translate(useLabel ? option.label : option.value),
  }));
};

export const MERCHANT_TYPE_OPTIONS = [
  {
    label: 'individual',
    value: 'individual',
  },
  {
    label: 'enterprise',
    value: 'enterprise',
  },
];

export const STORE_TYPE_OPTIONS = [
  {
    label: 'store_offline',
    value: OFFLINE_STORE,
  },
  {
    label: 'store_online',
    value: ONLINE_STORE,
  },
];

export const AGREEMENT_TYPE_OPTIONS = [
  {
    label: 'offline_gateway_agreement',
    value: OFFLINE_GATEWAY_AGREEMENT,
  },
  {
    label: 'online_gateway_agreement',
    value: ONLINE_GATEWAY_AGREEMENT,
  },
  {
    label: 'other_agreement',
    value: OTHER_AGREEMENT,
  },
];

export const CONTRACT_TYPE_OPTIONS = [
  {
    label: 'other_contract',
    value: OTHER_CONTRACT,
  },
  {
    label: 'yedpay_contract',
    value: YEDPAY_CONTRACT,
  },
];

export const RESET_PASSWORD_MEDIA_OPTIONS = [
  {
    label: 'email',
    value: 'email',
  },
  {
    label: 'phone',
    value: 'phone',
  },
];

export const FIRST_SET_PASSWORD_MEDIA_OPTIONS = [
  {
    label: 'email',
    value: 'email',
  },
  {
    label: 'phone',
    value: 'phone',
  },
];

export const SETTLEMENT_OPTIONS = [
  {
    label: 'enabled',
    value: true,
  },
  {
    label: 'disabled',
    value: false,
  },
];

export const PROTOCOL_TYPE = [
  {
    label: 'http://',
    value: HTTP,
  },
  {
    label: 'https://',
    value: HTTPS,
  },
];

export const DOMAIN_TYPE = [
  {
    label: 'website',
    value: WEB,
  },
  {
    label: 'application',
    value: APP,
  },
];

export const reactSelectBoolean = [
  {
    label: 'true',
    value: true,
  },
  {
    label: 'false',
    value: false,
  },
];

export const MERCHANT_TYPE_INDIVIDUAL = 'individual';
export const MERCHANT_TYPE_ENTERPRISE = 'enterprise';
export const TAXI_MCC = 4121;

export const MAX_UPLOAD_NUMBER = 10;
export const MAX_UPLOAD_SIZE = 4 * Math.pow(2, 20); // 2 ^ 20 = 1MB * 4 = 4MB

export const BR_NUMBER_LENGTH = 8;
export const BRANCH_NUMBER_LENGTH = 3;

export const TYPE_OF_BUSINESS = [
  //@todo remove the default option after input select has provide a default option
  {
    label: 'Please Select Type of Business',
    value: '',
  },
  {
    label: 'Individual',
    value: 1,
  },
  {
    label: 'Body Corporate',
    value: 2,
  },
  {
    label: 'Partnership',
    value: 3,
  },
  {
    label: 'Un-corporate',
    value: 4,
  },
];

export const CANCELED = 'canceled';
export const CANCELLED = 'cancelled';
export const ENABLED = 'enabled';
export const FAILED = 'failed';
export const PAID = 'paid';
export const REFUNDED = 'refunded';
export const SUCCESS = 'success';
export const VOID = 'void';

export const AUTHORIZED = 'authorized';
export const CAPTURED = 'captured';
export const PARTIAL_CAPTURED = 'partial_captured';

export const ACTIVATED = 'activated';
export const BALANCE_ADJUSTMENT = 'txn_balance_adjustment';
export const CHARGEBACK = 'chargeback';
export const COMPLETED = 'completed';
export const DISABLED = 'disabled';
export const OBSOLETED = 'obsoleted';
export const PENDING = 'pending';
export const PENDING_REFUND = 'pending_refund';
export const PENDING_CAPTURE = 'pending_capture';
export const PRECREATED = 'precreated';
export const PROCESSING = 'processing';
export const REJECTED = 'rejected';
export const REVOKED = 'revoked';
export const TERMINATED = 'terminated';
export const WAITING_FROM_BANK = 'waiting_from_bank';

export const LEDGER = 'ledger';
export const PENDING_CARD_DECISION = 'pending_card_decision';
export const PENDING_CANCEL = 'pending_cancel';
export const EXPIRED = 'expired';

export const BALANCE_HOLDING = 'balance_holding';
export const BALANCE_SETTLED = 'balance_settled';
export const REFUND = 'refund';
export const RESTRICTED = 'restricted';
export const REVERSE = 'reverse_settlement';
export const SALE = 'sale';
export const SETTLED = 'settled';
export const SETTLEMENT_REQUESTED = 'settlement_requested';
export const UNDER_REVIEW = 'under_review';

export const TOP_UP = 'top-up';
export const TOP_UP_ALREADY = 'top_up_already';

export const HIDDEN = 'hidden';
export const VISIBLE = 'visible';

export const INVALID = 'invalid';

export const SUSPENDED = 'suspended';
export const CLOSED = 'closed';

export const TRANSACTION_STATUS_COLOR: ANY_OBJECT = {
  [PENDING]: 'warning',
  [PENDING_REFUND]: 'warning',
  [PRECREATED]: 'warning',
  [VOID]: 'light',
  [CANCELLED]: 'light',
  [REFUNDED]: 'success',
  [FAILED]: 'danger',
  [CHARGEBACK]: 'danger',
  [PAID]: 'primary',

  [SALE]: 'primary',
  [SETTLEMENT_REQUESTED]: 'warning',
  [REVERSE]: 'light',
  [REFUND]: 'success',

  [TOP_UP]: 'primary',

  [BALANCE_ADJUSTMENT]: 'danger',
};

export const SETTLEMENT_STATUS_COLOR: ANY_OBJECT = {
  [PENDING]: 'warning',
  [PROCESSING]: 'warning',
  [WAITING_FROM_BANK]: 'warning',
  [COMPLETED]: 'success',
  [REJECTED]: 'danger',
  [CANCELLED]: 'light',
  [CANCELED]: 'light',
};

export const BALANCE_STATUS_COLOR: ANY_OBJECT = {
  [BALANCE_HOLDING]: 'warning',
  [BALANCE_SETTLED]: 'success',
  [TOP_UP_ALREADY]: 'success',
};

export const AML_DOMAIN_STATUS_COLOR: ANY_OBJECT = {
  [PENDING]: 'warning',
  [PROCESSING]: 'warning',
  [ACTIVATED]: 'success',
  [DISABLED]: 'danger',
  [REJECTED]: 'danger',
  [OBSOLETED]: 'danger',
  [TERMINATED]: 'danger',
};

export const STORE_STATUS_COLOR: ANY_OBJECT = {
  [PENDING]: 'warning',
  [UNDER_REVIEW]: 'warning',
  [PROCESSING]: 'warning',
  [ACTIVATED]: 'success',
  [DISABLED]: 'danger',
};

export const TTP_DEVICE_STATUS_COLOR: ANY_OBJECT = {
  [ENABLED]: 'success',
  [RESTRICTED]: 'warning',
  [DISABLED]: 'danger',
  [REVOKED]: 'danger',
};

export const DISPLAY_STATUS_COLOR: ANY_OBJECT = {
  [HIDDEN]: 'danger',
  [VISIBLE]: 'success',
};

export const MERCHANT_TOKEN_STATUS_COLOR: ANY_OBJECT = {
  [ACTIVE]: 'success',
  [INACTIVE]: 'danger',
  [PENDING]: 'warning',
  [SUSPENDED]: 'danger',
};

export const TRANSACTION_STATUS_OPTIONS = [
  {
    label: PAID,
    value: PAID.toLowerCase(),
  },
  {
    label: PENDING_CAPTURE,
    value: PENDING_CAPTURE.toLowerCase(),
  },
  {
    label: CAPTURED,
    value: CAPTURED.toLowerCase(),
  },
  {
    label: PENDING_REFUND,
    value: PENDING_REFUND.toLowerCase(),
  },
  {
    label: PRECREATED,
    value: PRECREATED.toLowerCase(),
  },
  {
    label: CANCELLED,
    value: CANCELLED.toLowerCase(),
  },
  {
    label: REFUNDED,
    value: REFUNDED.toLowerCase(),
  },
  {
    label: FAILED,
    value: FAILED.toLowerCase(),
  },
  {
    label: VOID,
    value: VOID.toLowerCase(),
  },
];

export const PAY_PLUS_TRANSACTION_STATUS_OPTIONS = [
  {
    label: PENDING,
    value: PENDING.toLowerCase(),
  },
  {
    label: PAID,
    value: PAID.toLowerCase(),
  },
];

export const SETTLEMENT_STATUS_OPTIONS = [
  {
    label: PENDING,
    value: PENDING.toLowerCase(),
  },
  {
    label: PROCESSING,
    value: PROCESSING.toLowerCase(),
  },
  {
    label: COMPLETED,
    value: COMPLETED.toLowerCase(),
  },
  {
    label: REJECTED,
    value: REJECTED.toLowerCase(),
  },
  {
    label: CANCELLED,
    value: CANCELED.toLowerCase(),
  },
];

export const TRANSACTION_TYPE_OPTIONS = [
  {
    label: SALE,
    value: SALE.toLowerCase(),
  },
  {
    label: SETTLEMENT_REQUESTED,
    value: SETTLEMENT_REQUESTED.toLowerCase(),
  },
  {
    label: REVERSE,
    value: REVERSE.toLowerCase(),
  },
  {
    label: REFUND,
    value: REFUND.toLowerCase(),
  },
];

export const BALANCE_SETTLEMENT_OPTIONS = [
  {
    label: BALANCE_SETTLED,
    value: 1,
  },
  {
    label: BALANCE_HOLDING,
    value: 0,
  },
];

export const BOOLEAN_OPTIONS = [
  {
    label: 'yes',
    value: true,
  },
  {
    label: 'no',
    value: false,
  },
];

export const WALLET_SELECTION_FOR_FIXED_CODE = [
  {
    label: HK,
    value: HK,
  },
  {
    label: CN,
    value: CN,
  },
];

export const QR_CODE_STATUS_OPTIONS = [
  {
    label: 'active',
    value: ACTIVE.toLowerCase(),
  },
  {
    label: 'inactive',
    value: INACTIVE.toLowerCase(),
  },
];

export const PAY_LINK_STATUS_OPTIONS = [
  {
    label: 'active',
    value: 0,
  },
  {
    label: 'inactive',
    value: 1,
  },
];

export const DATE_RANGE_OPTIONS = [
  {
    label: 'date_range_today',
    value: { days: -0 },
  },
  {
    label: 'date_range_last_7_days',
    value: { days: -7 },
  },
  {
    label: 'date_range_last_1_month',
    value: { months: -1 },
  },
  {
    label: 'date_range_last_3_months',
    value: { months: -3 },
  },
  // {
  //   label: 'date_range_last_6_months',
  //   value: { months: -6 },
  // },
  // {
  //   label: 'date_range_this_year',
  //   value: { years: 0 },
  // },
  // {
  //   label: 'date_range_last_year',
  //   value: { years: -1 },
  // },
  // {
  //   label: 'date_range_all_date',
  //   value: { years: -999 },
  // },
  {
    label: 'date_range_custom_range',
    value: null,
  },
];

export const EMPTY_OPTIONS = {
  label: '',
  value: '',
};

export const IS_NGO_OPTION = [
  {
    label: 'true',
    value: true,
  },
  {
    label: 'false',
    value: false,
  },
];

export const PRE_AUTH_STATUS_OPTIONS = [
  {
    label: `preAuthStatus.${CANCELLED}`,
    value: CANCELLED,
  },
  {
    label: `preAuthStatus.${PENDING_CAPTURE}`,
    value: PENDING_CAPTURE,
  },
  {
    label: `preAuthStatus.${CAPTURED}`,
    value: CAPTURED,
  },
  {
    label: `preAuthStatus.${PARTIAL_CAPTURED}`,
    value: PARTIAL_CAPTURED,
  },
  {
    label: `preAuthStatus.${AUTHORIZED}`,
    value: AUTHORIZED,
  },
  {
    label: `preAuthStatus.${PRECREATED}`,
    value: PRECREATED,
  },
  {
    label: `preAuthStatus.${PENDING}`,
    value: PENDING,
  },
  {
    label: `preAuthStatus.${PENDING_CARD_DECISION}`,
    value: PENDING_CARD_DECISION,
  },
  {
    label: `preAuthStatus.${FAILED}`,
    value: FAILED,
  },
  {
    label: `preAuthStatus.${PENDING_CANCEL}`,
    value: PENDING_CANCEL,
  },
  {
    label: `preAuthStatus.${EXPIRED}`,
    value: EXPIRED,
  },
];

export const WALLET_TYPE_OPTIONS = [
  {
    label: HK,
    value: HK,
  },
  {
    label: CN,
    value: CN,
  },
  {
    label: OVERSEAS,
    value: OVERSEAS,
  },
];

export const MERCHANT_TOKEN_STATUS_OPTIONS = [
  {
    label: `merchantTokenStatus.${PENDING}`,
    value: PENDING,
  },
  {
    label: `merchantTokenStatus.${ACTIVE}`,
    value: ACTIVE,
  },
  {
    label: `merchantTokenStatus.${SUSPENDED}`,
    value: SUSPENDED,
  },
  {
    label: `merchantTokenStatus.${INACTIVE}`,
    value: INACTIVE,
  },
];

export const TOKEN_STATUS_OPTIONS = [
  {
    label: `tokenStatus.${PENDING}`,
    value: PENDING,
  },
  {
    label: `tokenStatus.${ACTIVE}`,
    value: ACTIVE,
  },
  {
    label: `tokenStatus.${SUSPENDED}`,
    value: SUSPENDED,
  },
  {
    label: `tokenStatus.${EXPIRED}`,
    value: EXPIRED,
  },
  {
    label: `tokenStatus.${CLOSED}`,
    value: CLOSED,
  },
  {
    label: `tokenStatus.${INVALID}`,
    value: INVALID,
  },
];
