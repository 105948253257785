import { useLocation } from 'react-router-dom';
import { getBalanceList } from 'actions/BalanceActions';
import { TransactionModal } from 'components/Transaction/TransactionModal';
import { BalanceBox } from 'components/custom/BalanceBox';
import { ReportSection } from 'components/custom/ReportSection';
import { Input } from 'components/custom/input';
import { SideFilter } from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { isAgent, isGatewayProvider } from 'constants/Roles';
import GATEWAY_ARRAY, { DBS_FPS_YEDPAY_ONLINE_CODE } from 'constants/TransactionGateways';
import {
  BALANCE_STATUS_COLOR,
  BALANCE_SETTLEMENT_OPTIONS,
  TIME_ZONE_FORMAT,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_TYPE_OPTIONS,
  translateLabel,
  SALE,
} from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { getNDaysEarlier } from 'helpers/functions/getDays';
import { useSwal } from 'helpers/sweetalert';
import moment from 'moment';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanyBalanceTransaction } from 'services/API/Balance';
import { BalanceTransactionProps } from 'services/API/Balance/interface';
import { getCompanyCredentialList } from 'services/API/Company';
import { dateRangeDurationLessEqualThan, packValidateResults } from 'helpers/validators';
import { isCanAccessTopUpBalance, owner } from 'constants/Roles';
import { topUpBalanceSidebarRoutes } from 'constants/Routes';
import { getFeaturesConfig } from 'actions/FeaturesConfigActions';
import { useHistory } from 'react-router-dom';

const initialFilterState = {
  total_balance: '',
  transaction_id: '',
  transaction_type: '',
  start: new Date(getNDaysEarlier(7)),
  end: new Date(),
  start_time: '00:00:00',
  end_time: '23:59:59',
  is_balance_settled: '',
  only_show_top_up_records: 0,
};

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }

  value.toString = () => value.id;
  return {
    label:
      GATEWAY_ARRAY.find((gateway) => gateway.gateway_code === value.gateway_code)
        ?.gateway_sub_name ?? value.gateway_sub_name,
    value: value,
  };
};

export const Balance = () => {
  const history = useHistory();
  const {
    auth: { user, roles, features },
    data: { balanceTransactions },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const location = useLocation();
  const dispatch = useDispatch<FunctionDispatch>();
  let allowExportCsvReport = true;
  if (features) {
    if (features?.balance_report?.store_count_limit) {
      const storeCount = user?.stores?.length;
      const limitCount = features?.balance_report?.store_count_limit;
      // disable export csv report if store count is greater or qual to the limit
      allowExportCsvReport = storeCount < limitCount;
    }
  }

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const { balanceTransactions: balanceData, balanceTransactionsPagination } = balanceTransactions;

  const reportSectionRef = useRef<any>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const [filter, setFilter] = useState(initialFilterState);
  const [isFetching, setIsFetching] = useState(false);
  const [isTopUpRelatedFilterHidden, setIsTopUpRelatedFilterHidden] = useState(false);
  const [isFetchFeature, setIsFetchFeature] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedBalance, setSelectedBalance] = useState<null | BalanceTransactionProps>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gateways, setGateways] = useState<Array<any>>([]);

  const [openExportDropdown, setOpenExportDropdown] = useState(false);
  const [openExportDropdownLegacy, setOpenExportDropdownLegacy] = useState(false);
  const [queryParams, setQueryParams] = useState<Record<string, any>>({});

  const {
    start,
    end,
    start_time,
    end_time,
    total_balance,
    transaction_id,
    transaction_type,
    is_balance_settled,
    only_show_top_up_records,
  } = filter;

  const getInitialData = async () => {
    const data = filterToParams();
    setIsFetching(true);
    setIsFetchFeature(true);
    const res = await dispatch(getBalanceList(user?.company_id, data));
    setIsFetching(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('balance'),
        text: res.message || 'Oops',
      });
    }
    if ([owner].includes(user?.role)) {
      // reload the feature config if the user is owner
      const featureRes = await dispatch(getFeaturesConfig());
      setIsFetchFeature(false);
    } else {
      setIsFetchFeature(false);
    }
  };

  const getCredentialOptions: LoadOptions<any, any> = async () => {
    const result = await getCompanyCredentialList(user?.company_id);

    const hasMore = !!result?.meta?.pagination?.links.next ?? false;

    return {
      options: result.data.map(valueToLabel),
      hasMore,
    };
  };

  const exportReport = async (type: string) => {
    const data = filterToParams();

    // date range limit check
    const validationResult = daysLimitCheck();
    if (!validationResult) {
      return;
    }

    data.export = type;
    data.limit = balanceTransactionsPagination.total || 0;

    setIsFetching(true);
    const res = await getCompanyBalanceTransaction(user?.company_id, data);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for the report to be generated
    await reportSectionRef?.current?.loadReports();
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('export'),
        text: res.message || 'Oops',
      });
      setIsFetching(false);
      return;
    }
    setIsFetching(false);
  };

  const onFilterDateChange = (date: Date, name: string) => {
    setFilter({
      ...filter,
      [name]: date,
    });
  };

  const onFilterChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'balance') {
      // Cleave's Event handler has rawValue
      setFilter({
        ...filter,
        total_balance: (e.target as any).rawValue,
      });
      return;
    }

    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFilterCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTopUpRelatedFilterHidden(e.target.checked);

    if (e.target.checked) {
      // reset hidden filter values
      setFilter({
        ...filter,
        ['transaction_type']: initialFilterState.transaction_type,
        ['is_balance_settled']: initialFilterState.is_balance_settled,
        ['only_show_top_up_records']: 1,
      });
      setGateways([]);
    } else {
      setFilter({
        ...filter,
        ['only_show_top_up_records']: 0,
      });
      setTimeout(() => {
        document.getElementById('side-content')?.scrollTo({
          top: document.getElementById('side-content')?.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // date range limit check
    const validationResult = daysLimitCheck();
    if (!validationResult) {
      return;
    }

    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const filterToParams = () => {
    const data: { [key: string]: string } = {
      transaction_type,
      is_balance_settled,
      total_balance,
      transaction_id,
      gateway_code: gateways.map((g) => g.gateway_code).join(','),
    };

    // special handle if only_show_top_up_records is checked
    if (only_show_top_up_records === 1) {
      data.gateway_code = DBS_FPS_YEDPAY_ONLINE_CODE;
    }

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    const start_date_string: string = moment(start).format('YYYY-MM-DD').slice(0, 10);
    const end_date_string: string = moment(end).format('YYYY-MM-DD').slice(0, 10);

    const formatTime = (time: string, default_time: string): string => {
      if (time.trim() === '') {
        return default_time;
      }

      if (/^\d$/.test(time)) {
        return `0${time}:00:00`;
      }

      return time;
    };

    const start_time_string: string = formatTime(start_time, initialFilterState.start_time);
    const end_time_string: string = formatTime(end_time, initialFilterState.end_time);

    Object.assign(data, {
      'created_at>': moment(start_date_string + ' ' + start_time_string).format(TIME_ZONE_FORMAT),
      'created_at<': moment(end_date_string + ' ' + end_time_string).format(TIME_ZONE_FORMAT),
      limit: 10,
      page: currentPage,
      include: 'transaction',
    });

    return data;
  };

  function daysLimitCheck() {
    const maxLimitDays = 97;
    const { start, end, start_time, end_time } = filter;

    const checkDateResult = packValidateResults([
      dateRangeDurationLessEqualThan({ start, end }, ['start', 'end'], {
        max: maxLimitDays,
        unit: translate('unit_day'),
        start_date_time: start_time,
        end_date_time: end_time,
      }),
    ]);
    if (!checkDateResult.isValid) {
      Swal.fire({
        icon: 'error',
        title: translate('error'),
        text: translate('error_duration_must_be_less_equal_than', [
          maxLimitDays.toString(),
          translate('unit_day'),
        ]),
      });
      return false;
    }

    return true;
  }

  const resetFilter = () => {
    setFilter(initialFilterState);
    setGateways([]);
    setIsTopUpRelatedFilterHidden(false);
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0">{translate('date')}</th>
        <th className="border-end border-top-0">{translate('transaction_id')}</th>
        <th className="border-end border-top-0">{translate('gateway')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('fee')}</th>
        <th className="border-end border-top-0">{translate('net')}</th>
        <th className="border-end border-top-0">{translate('balance_available')}</th>
        <th className="border-end border-top-0">{translate('transaction_type')}</th>
        <th className="border-end border-top-0">{translate('settlement_status')}</th>
      </tr>
    );
  };

  const renderData = () => {
    return balanceData.map((balance, index) => {
      const {
        created_at,
        sale,
        charge,
        balance: amount,
        total_balance,
        currency,
        transaction_type,
      } = balance;
      const isSettled = balance.transaction.data.is_balance_settled;
      const isTopUpBalance = balance.is_topup_balance;
      const settlement_status = isSettled ? 'balance_settled' : 'balance_holding';

      const onClick = () => {
        setSelectedBalance(balance as any);
        setIsModalOpen(true);
      };

      return (
        <tr className="cursor-pointer" key={index} onClick={onClick}>
          <td className="">{created_at}</td>
          <td className="">{(balance as any).transaction.data.transaction_id}</td>
          <td className="">{balance.transaction.data.gateway_sub_name}</td>
          <td className="">
            <span className="amount" data-currency={currency}>
              {sale}&nbsp;
            </span>
          </td>
          <td className="">{charge}</td>
          <td className="">{amount}</td>
          <td className="">{total_balance}</td>
          <td className="">
            <span
              className={`text-${TRANSACTION_STATUS_COLOR[transaction_type]} p-2 text-uppercase`}>
              {translate(transaction_type)}
            </span>
            {isTopUpBalance && (
              <span
                className={`text-${TRANSACTION_STATUS_COLOR[transaction_type]} p-0 text-lowercase`}>
                ({translate('top-up')})
              </span>
            )}
          </td>
          <td className={`text-${BALANCE_STATUS_COLOR[settlement_status]}`}>
            {translate(settlement_status)}
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    // * ALICE-658: support url query to export report with legacy implementation
    // use whitelist to prevent prototype pollution
    const allowedKeys = ['debug'];
    const queryParams = {};
    new URLSearchParams(location.search).forEach((value, key) => {
      if (allowedKeys.includes(key)) {
        Object.assign(queryParams, { [key]: value });
      }
    });
    setQueryParams(queryParams);

    // reset query on unmount
    return () => {
      setQueryParams({});
    };
  }, [location.search]);

  return (
    <>
      <TopHeader title={translate('balance')}>
        {/* ALICE-658: only show when debug=1 */}
        <div className="d-flex gap-1">
          {!isFetchFeature && isCanAccessTopUpBalance(features, user?.role) && (
            <>
              <button
                className="header-button btn btn-primary"
                onClick={() => history.push(topUpBalanceSidebarRoutes[0].path)}
                disabled={isFetching}>
                {translate('top_up_balance')}
              </button>
              &nbsp;
            </>
          )}
          {queryParams.debug === '1' ? (
            <Dropdown
              disabled={isFetching}
              isOpen={openExportDropdownLegacy}
              toggle={() => setOpenExportDropdownLegacy(!openExportDropdownLegacy)}
              className="d-inline-block mr-1">
              <DropdownToggle
                disabled={isFetching}
                className="header-button btn btn-warning"
                caret
                color="#ffffff">
                {isFetching ? <Spinner size="sm" /> : translate('export_legacy')}
              </DropdownToggle>
              <DropdownMenu classnames="lang-dropdown">
                <DropdownItem
                  classnames="btn btn-sm btn-muted text-center"
                  onClick={() => exportReport('xlsx - 202411')}>
                  {translate('generate_excel_report')}
                </DropdownItem>
                {allowExportCsvReport && (
                  <DropdownItem
                    classnames="btn btn-sm btn-muted text-center"
                    onClick={() => exportReport('csv - 202411')}>
                    {translate('generate_csv_report')}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          ) : null}
          <Dropdown
            disabled={isFetching}
            isOpen={openExportDropdown}
            toggle={() => setOpenExportDropdown(!openExportDropdown)}
            className="d-inline-block">
            <DropdownToggle
              disabled={isFetching}
              className="header-button btn btn-success"
              caret
              color="#ffffff">
              {isFetching ? <Spinner size="sm" /> : translate('export')}
            </DropdownToggle>
            <DropdownMenu classnames="lang-dropdown">
              <DropdownItem
                classnames="btn btn-sm btn-muted text-center"
                onClick={() => exportReport('xlsx')}>
                {translate('generate_excel_report')}
              </DropdownItem>
              {allowExportCsvReport && (
                <DropdownItem
                  classnames="btn btn-sm btn-muted text-center"
                  onClick={() => exportReport('csv')}>
                  {translate('generate_csv_report')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </TopHeader>
      <div id="main-content" className="hide-scrollbar">
        <CustomTable
          renderHeading={() => tableHeader()}
          renderData={renderData}
          totalColumn={9}
          setCurrentPage={setCurrentPage}
          pagination={balanceTransactionsPagination}
        />
      </div>
      <div id="side-content" className="hide-scrollbar">
        <ReportSection ref={reportSectionRef} type="balances" />
        {!roles.isOperator && <BalanceBox />}
        <SideFilter isLoading={isFetching} onFilter={onFilterSearch} resetFilter={resetFilter}>
          <Input
            type="date"
            value={start}
            legend={translate('from').toUpperCase()}
            name="start"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              time: true,
              timePattern: ['h', 'm', 's'],
            }}
            value={start_time}
            legend={translate('time').toUpperCase()}
            name="start_time"
            onChange={onFilterChange}
            placeholder="hh:mm:ss"
          />
          <Input
            type="date"
            value={end}
            legend={translate('to').toUpperCase()}
            name="end"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              time: true,
              timePattern: ['h', 'm', 's'],
            }}
            value={end_time}
            legend={translate('time').toUpperCase()}
            name="end_time"
            onChange={onFilterChange}
            placeholder="hh:mm:ss"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              numeral: true,
              numeralDecimalScale: 2,
              numeralIntegerScale: 7,
              numeralPositiveOnly: true,
            }}
            legend={translate('balance').toUpperCase()}
            value={total_balance}
            name="total_balance"
            onChange={onFilterChange}
            placeholder={translate('balance')}
          />
          <Input
            type="text"
            legend={translate('transaction_id').toUpperCase()}
            value={transaction_id}
            name="transaction_id"
            onChange={onFilterChange}
            placeholder={translate('transaction_id')}
          />
          {
            <Input
              type="react-select"
              legend={translate('transaction_type').toUpperCase()}
              value={transaction_type}
              options={translateLabel(TRANSACTION_TYPE_OPTIONS, translate)}
              defaultLabel={translate('all_types')}
              name="transaction_type"
              onChange={onFilterSelectChange('transaction_type')}
              placeholder={translate('transaction_type')}
              menuPortalTarget={document.body}
            />
          }
          {
            <Input
              type="react-select"
              legend={translate('settlement_status').toUpperCase()}
              value={is_balance_settled}
              options={translateLabel(BALANCE_SETTLEMENT_OPTIONS, translate)}
              defaultLabel={translate('all_types')}
              name="is_balance_settled"
              onChange={onFilterSelectChange('is_balance_settled')}
              // placeholder={translate('settlement_status')}
              menuPortalTarget={document.body}
            />
          }
          {!isGatewayProvider(user.role) && !isAgent(user.role) && !isTopUpRelatedFilterHidden && (
            <Input
              isMulti
              type="react-select-async"
              legend={translate('gateway').toUpperCase()}
              loadOptions={getCredentialOptions}
              value={gateways.map(valueToLabel)}
              name="gateways"
              onChange={setGateways}
              placeholder={translate('gateway')}
              // defaultLabel={translate('all_gateways')}
              menuPortalTarget={document.body}
            />
          )}
          {features?.top_up_balance?.enable && (
            <Input
              type="checkbox"
              name=""
              value={only_show_top_up_records}
              onChange={onFilterCheckBoxChange}
              focusRef={checkboxRef}>
              <div className="d-flex">
                <span style={{ color: '#9092a5' }}>{translate('only_show_top_up_records')}</span>
              </div>
            </Input>
          )}
        </SideFilter>
      </div>
      {selectedBalance && (
        <TransactionModal
          isAgentOrGatewayProvider={isAgent(user.role) || isGatewayProvider(user.role)}
          reloadTransaction={getInitialData}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          transaction={(selectedBalance as any).transaction.data as any}
        />
      )}
    </>
  );
};
