import { faCheck, faDownload, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from 'context/TranslateContext';
import { forwardRef, useImperativeHandle } from 'react';
import './report.scss';
import { useGetData } from './useGetData';

interface ReportSectionProps {
  type: string;
}

export const ReportSection = forwardRef(({ type }: ReportSectionProps, ref) => {
  const { translate } = useTranslate();
  const [isLoading, reports, loadReports] = useGetData(type);
  useImperativeHandle(ref, () => ({ loadReports }));

  if (reports.length === 0) {
    return <></>;
  }
  return (
    <div className="bg-white mb-4 round-sm shadow-sm">
      <div className="px-4 py-3 border-bottom">
        {translate('reports')}{' '}
        {isLoading ? (
          <span className="text-warning" style={{ width: '1rem' }}>
            <FontAwesomeIcon icon={faSpinner} spin />
          </span>
        ) : null}
      </div>
      <div className="px-4 py-3">
        {reports.map((report) => (
          <div key={report.id}>
            {!report.isLoading && report.path && (
              <a href={report.path} onClick={loadReports}>
                <div className="reportRow">
                  <span>
                    {translate('report')} {report.updated_at}
                  </span>
                  <span style={{ width: '1rem', textAlign: 'center' }}>
                    {report.downloaded && (
                      <FontAwesomeIcon className="text-success" icon={faCheck} />
                    )}
                    {!report.downloaded && <FontAwesomeIcon icon={faDownload} />}
                  </span>
                </div>
              </a>
            )}

            {report.isLoading && (
              <span>
                <div className="reportRow">
                  <span>{translate('generating_report')}...</span>
                  <span className="text-warning" style={{ width: '1rem', textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </span>
                </div>
              </span>
            )}

            {!report.path && report.file_name && (
              <span>
                <div className="reportRow">
                  <span>{translate('failed_to_generate_report')}</span>
                  <span className="text-danger" style={{ width: '1rem', textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});
