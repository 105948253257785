import { receiveData } from 'actions/DataAction';
import { getCompanyTransactionList, getUserTransactionList } from 'actions/TransactionActions';
import { TransactionModal as CreateTransactionModal } from 'components/Account/summary/createTransaction';
import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { ReportSection } from 'components/custom/ReportSection';
import { SideFilter } from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import {
  REFUNDED,
  TIME_ZONE_FORMAT,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_OPTIONS,
  WALLET_TYPE_OPTIONS,
  translateLabel,
} from 'constants/format';
import { ALLOW_CREATE_SETTLEMENTS_ROLES, isAgent, isGatewayProvider } from 'constants/Roles';
import GATEWAY_ARRAY, { DBS_FPS_YEDPAY_ONLINE_CODE } from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { getNDaysEarlier } from 'helpers/functions/getDays';
import { round, set } from 'lodash';
import moment from 'moment';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanyCredentialList, getCompanyList } from 'services/API/Company';
import { GatewayProviderGetCompanyProps } from 'services/API/Company/interface';
import { getCompanyFixedCodesList } from 'services/API/FixedCode';
import { getAllStore } from 'services/API/Store';
import { TransactionProps } from 'services/API/Transactions/interface';
import { useSwal } from 'helpers/sweetalert';
import './index.scss';
import { TransactionModal } from './TransactionModal';
import { dateRangeDurationLessEqualThan, packValidateResults } from 'helpers/validators';

const filterInitialState = {
  from: new Date(getNDaysEarlier(31)),
  to: new Date(),
  start_date_time: '00:00:00',
  end_date_time: '23:59:59',
  amount: '',
  transaction_id: '',
  reference_id: '',
  custom_id: '',
  store: '',
  fixed_code: '',
  status: '',
  wallet: '',
  merchant_token_id: '',
  merchant_token_custom_id: '',
  only_show_top_up_records: 0,

  company_id: '',
};

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }

  value.toString = () => value.id;
  return {
    label:
      GATEWAY_ARRAY.find((gateway) => gateway.gateway_code === value.gateway_code)
        ?.gateway_sub_name ?? value.gateway_sub_name,
    value: value,
  };
};
export const Transactions = () => {
  const dispatch = useDispatch<FunctionDispatch>();

  const {
    auth: { user, credentials, features },
    data: {
      transactions: { transactions, transactionsPagination },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [filter, setFilter] = useState(filterInitialState);
  const [orderBy, setOrderBy] = useState<'created_at' | 'updated_at'>('created_at');

  const [storeOptions, setStoreOptions] = useState<Array<SelectOptionProps>>([]);
  const [fixedCodeOptions, setFixedCodeOptions] = useState<Array<SelectOptionProps>>([]);

  const [isFetching, setIsFetching] = useState(false);
  const [isTopUpRelatedFilterHidden, setIsTopUpRelatedFilterHidden] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState<TransactionProps>();
  const [showTransactionDetailModal, setShowTransactionDetailModal] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);

  const [isExportDropdownLoading, setIsExportDropdownLoading] = useState(false);
  const [openExportDropdown, setOpenExportDropdown] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);
  const [companyPage, setCompanyPage] = useState(1);
  const [companyList, setCompanyList] = useState<Array<GatewayProviderGetCompanyProps>>([]);
  const [gateways, setGateways] = useState<Array<any>>([]);
  const reportSectionRef = useRef<any>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const ORDER_TYPE = [
    {
      label: 'created_at',
      value: 'created_at',
    },
    {
      label: 'updated_at',
      value: 'updated_at',
    },
  ];

  const {
    from,
    to,
    start_date_time,
    end_date_time,
    amount,
    transaction_id,
    reference_id,
    custom_id,
    store,
    fixed_code,
    status,
    wallet,
    company_id,
    merchant_token_id,
    merchant_token_custom_id,
    only_show_top_up_records,
  } = filter;

  const findCompanyName = () => {
    return companyList.find((company) => company.id === company_id)?.name ?? 'Company';
  };

  const onCompanyNameSelect = (company_id: string) => {
    setFilter({
      ...filter,
      company_id,
    });
  };

  const searchCompany: LoadOptions<any, any> = async (input) => {
    let options: Array<any> = [];

    if (hasMore) {
      const data = Object.assign({ page: companyPage });
      const res = await getCompanyList(data);
      const page = res?.meta?.pagination?.current_page ?? 1;
      const hasMore = !!res?.meta?.pagination?.links?.next ?? false;

      if (hasMore) {
        setCompanyPage(page + 1);
      } else {
        setHasMore(false);
      }

      options = [...res.data];
      setCompanyList([...companyList, ...res.data]);
    }

    let filtered: Array<any> = [];

    if (!input) {
      filtered = [...convertCompaniesOptions(options)];
    } else {
      const searchLowerCase = input.toLowerCase();
      if (searchLowerCase.length < 3) {
        filtered = [...convertCompaniesOptions(companyList)];
      } else {
        const result = await getCompanyList({
          name: `*${searchLowerCase}*`,
        });
        setCompanyList(Array.from(new Set([...companyList, ...result.data])));
        filtered = convertCompaniesOptions(result.data);
      }
    }

    return {
      options: filtered,
      hasMore,
    };
  };

  const convertCompaniesOptions = (companies: Array<GatewayProviderGetCompanyProps>) => {
    return companies.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  };

  const selectTransaction = (transaction: TransactionProps | undefined) => {
    setSelectedTransaction(transaction);
    setShowTransactionDetailModal(true);
  };

  const getInitialData = async () => {
    const params = filterToParams();
    // date range limit check
    const validationResult = daysLimitCheck();
    if (!validationResult) {
      return;
    }
    setIsFetching(true);
    let res: any = {};
    if (isGatewayProvider(user.role) || isAgent(user.role)) {
      res = await dispatch(getUserTransactionList(user.id, params));
    } else {
      res = await dispatch(getCompanyTransactionList(user.company_id, params));
    }
    try {
      dispatch(receiveData(res, 'transactions'));
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  const getStoreOptions = async () => {
    const res = await getAllStore(user?.company_id);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('store'),
        text: res?.message || 'Oops',
      });
      return;
    }
    const list = res.data.map((store) => {
      return {
        label: store.name,
        value: store.id,
      };
    });
    setStoreOptions(list);
  };

  const getFixedCodesOptions = async () => {
    const res = await getCompanyFixedCodesList(user?.company_id);
    if (!res.success) {
      Swal.fire({
        title: translate('fixed_qr_code'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    const list = res.data;
    const options = list.map((fixedCode) => {
      return {
        label: fixedCode.name,
        value: fixedCode.id,
      };
    });
    setFixedCodeOptions(options);
  };

  const getCredentialOptions: LoadOptions<any, any> = async () => {
    const result = await getCompanyCredentialList(user?.company_id);

    const hasMore = !!result.meta?.pagination?.links.next ?? false;

    return {
      options: result.data.map(valueToLabel),
      hasMore,
    };
  };

  const exportReport = async (type: string) => {
    const params = filterToParams(true);
    // date range limit check
    const validationResult = daysLimitCheck();
    if (!validationResult) {
      return;
    }
    setIsExportDropdownLoading(true);

    params.asyncExport = true;
    params.export = type;

    let res: any = null;
    if (isGatewayProvider(user.role) || isAgent(user.role)) {
      res = await dispatch(getUserTransactionList(user.id, params));
    } else {
      res = await dispatch(getCompanyTransactionList(user.company_id, params, false));
    }

    await reportSectionRef?.current?.loadReports();
    setIsExportDropdownLoading(false);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('export'),
        text: res.message || 'Oops',
      });
      return;
    }
  };

  const onFilterDateChange = (date: Date, name: string) => {
    setFilter({
      ...filter,
      [name]: date,
    });
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === 'amount') {
      // Cleave's Event handler has rawValue
      setFilter({
        ...filter,
        amount: (e.target as any).rawValue,
      });
      return;
    }

    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFilterCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTopUpRelatedFilterHidden(e.target.checked);

    if (e.target.checked) {
      // reset hidden filter values
      setFilter({
        ...filter,
        ['wallet']: filterInitialState.wallet,
        ['status']: filterInitialState.status,
        ['only_show_top_up_records']: 1,
      });
      setGateways([]);
    } else {
      setFilter({
        ...filter,
        ['only_show_top_up_records']: 0,
      });
      setTimeout(() => {
        document.getElementById('side-content')?.scrollTo({
          top: document.getElementById('side-content')?.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const filterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // date range limit check
    const validationResult = daysLimitCheck();
    if (!validationResult) {
      return;
    }

    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  function daysLimitCheck() {
    const maxLimitDays = 97;
    const { from, to, start_date_time, end_date_time } = filter;

    const checkDateResult = packValidateResults([
      dateRangeDurationLessEqualThan({ from, to }, ['from', 'to'], {
        max: maxLimitDays,
        unit: translate('unit_day'),
        start_date_time,
        end_date_time,
      }),
    ]);

    if (!checkDateResult.isValid) {
      Swal.fire({
        icon: 'error',
        title: translate('error'),
        text: translate('error_duration_must_be_less_equal_than', [
          maxLimitDays.toString(),
          translate('unit_day'),
        ]),
      });
      return false;
    }

    return true;
  }

  const resetFilter = () => {
    const params = new URLSearchParams(window.location.search);
    // remove the transaction_id from the url
    params.delete('transaction_id');
    params.delete('merchant_token_id');
    window.history.pushState({}, '', `${window.location.pathname}`);
    filter.transaction_id = '';
    filter.merchant_token_id = '';

    setFilter(filterInitialState);
    setGateways([]);
    setIsTopUpRelatedFilterHidden(false);
  };

  const filterToParams = (exportData = false) => {
    const {
      from,
      to,
      start_date_time,
      end_date_time,
      amount,
      transaction_id,
      reference_id,
      custom_id,
      store,
      fixed_code,
      status,
      wallet,
      company_id,
      merchant_token_id,
      merchant_token_custom_id,
      only_show_top_up_records,
    } = filter;
    const query: { [key: string]: any } = {};

    const params = new URLSearchParams(window.location.search);
    if (transaction_id != '') {
      params.set('transaction_id', transaction_id);
    } else {
      params.delete('transaction_id');
    }

    if (merchant_token_id != '') {
      params.set('merchant_token_id', merchant_token_id);
    } else {
      params.delete('merchant_token_id');
    }

    const paramsSize = Array.from(params).length;
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}${paramsSize > 0 ? '?' + params : ''}`,
    );

    const start: string = moment(from).format('YYYY-MM-DD').slice(0, 10);
    const end: string = moment(to).format('YYYY-MM-DD').slice(0, 10);

    let gateway_code = gateways.map((g) => g.gateway_code).join(',');

    // special handle if only_show_top_up_records is checked
    if (only_show_top_up_records === 1) {
      gateway_code = DBS_FPS_YEDPAY_ONLINE_CODE;
    }

    Object.assign(
      query,
      {
        [orderBy + '>']: moment(start + ' ' + start_date_time).format(TIME_ZONE_FORMAT),
        [orderBy + '<']: moment(end + ' ' + end_date_time).format(TIME_ZONE_FORMAT),
        gateway_code: gateway_code,
        amount,
        transaction_id,
        store_id: store,
        fixed_id: fixed_code,
        status,
        wallet,
        custom_id,
        reference_id,
        ...(features?.tokenization?.enable && {
          merchant_token_id: merchant_token_id,
          merchant_token_custom_id: merchant_token_custom_id,
        }),
      },
      company_id && { company_id },
      exportData && { export: 'xlsx', limit: transactionsPagination.total },
      { page: currentPage, limit: 10, sort: `-${orderBy}` },
    );

    const names = Object.keys(query);
    const values = Object.values(query);

    values.forEach((val, index) => {
      if (typeof val === 'string' && (val === '' || val === '0')) {
        delete query[names[index]];
      }
      if (typeof val === 'number' && val === 0) {
        delete query[names[index]];
      }
    });

    return query;
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0">{translate('transaction_id')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('fee')}</th>
        <th className="border-end border-top-0">{translate('net')}</th>
        <th className="border-end border-top-0">{translate('store')}</th>
        <th className="border-end border-top-0">{translate('gateway')}</th>
        <th className="border-end border-top-0">{translate('wallet')}</th>
        <th className="border-end border-top-0">{translate(orderBy)}</th>
        <th className="border-end border-top-0">{translate('can_settle_after')}</th>
        <th className="border-top-0">{translate('status')}</th>
      </tr>
    );
  };

  const onOrderByChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setOrderBy(e.target.value as any);
  };

  const renderData = () => {
    return transactions.map((transaction, index) => {
      const {
        amount,
        currency,
        charge,
        refund_charge,
        net,
        store_id,
        gateway_code,
        wallet_type,
        status,
        transaction_id,
        can_settle_after,
      } = transaction;

      const gateway_name = GATEWAY_ARRAY.find(
        (gateway) => gateway.gateway_code === gateway_code,
      )?.gateway_sub_name;

      return (
        <tr
          key={index}
          className="cursor-pointer"
          onClick={() => selectTransaction(transaction as unknown as TransactionProps)}>
          <td>
            <span>{transaction_id}</span>
          </td>
          <td>
            <div className="amount" data-currency={currency}>
              {amount}&nbsp;
            </div>
          </td>
          <td>
            <span className="amount" data-currency={currency}>
              {round(parseFloat(charge) + parseFloat(refund_charge), 2)}&nbsp;
            </span>
          </td>
          <td>
            <span className="amount" data-currency={currency}>
              {net}&nbsp;
            </span>
          </td>
          <td>
            <span>{storeOptions.find((store) => store.value === store_id)?.label}</span>
          </td>
          <td>
            <span>{gateway_name}</span>
          </td>
          <td>{translate(wallet_type)}</td>
          <td>
            <span>{transaction[orderBy]}</span>
          </td>
          <td>
            <span>{can_settle_after ? moment(can_settle_after).format('YYYY-MM-DD') : 'N/A'}</span>
          </td>
          <td>
            <div className={`text-${TRANSACTION_STATUS_COLOR[status]} px-2 text-uppercase`}>
              {translate(status)}
            </div>
            {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
              <div className="amount text-light" data-currency={currency}>
                {transaction.refunded}&nbsp;
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    if (!isGatewayProvider(user.role) && !isAgent(user.role)) {
      getStoreOptions();
      getFixedCodesOptions();
    }
    return () => {
      // reset all filter on unmount
      resetFilter();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const getPresetTransactionId = params.get('transaction_id');
    if (getPresetTransactionId && getPresetTransactionId != '') {
      filter.transaction_id = getPresetTransactionId;
    }
    const getPresetMerchantTokenId = params.get('merchant_token_id');
    if (getPresetMerchantTokenId && getPresetMerchantTokenId != '') {
      filter.merchant_token_id = getPresetMerchantTokenId;
    }

    getInitialData();
  }, [currentPage, orderBy]);

  return (
    <>
      <TopHeader title={translate('transactions')}>
        {ALLOW_CREATE_SETTLEMENTS_ROLES.includes(user.role) && (
          <button
            className="header-button btn btn-success"
            onClick={() => setOpenTransactionModal(true)}>
            {translate('create_transaction')}
          </button>
        )}
        &nbsp;
        <Dropdown
          disabled={isExportDropdownLoading}
          isOpen={openExportDropdown}
          toggle={() => setOpenExportDropdown(!openExportDropdown)}
          className="d-inline-block">
          <DropdownToggle
            disabled={isExportDropdownLoading}
            className="header-button btn btn-primary"
            caret
            color="#ffffff">
            {isExportDropdownLoading ? <Spinner size="sm" /> : translate('export_transaction')}
          </DropdownToggle>
          <DropdownMenu classnames="lang-dropdown">
            <DropdownItem
              classnames="btn btn-sm btn-muted text-center"
              onClick={() => exportReport('xlsx')}>
              {translate('generate_excel_report')}
            </DropdownItem>
            <DropdownItem
              classnames="btn btn-sm btn-muted text-center"
              onClick={() => exportReport('csv')}>
              {translate('generate_csv_report')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </TopHeader>
      <div id="main-content" className="hide-scrollbar">
        <CustomTable
          totalColumn={10}
          renderHeading={() => tableHeader()}
          renderData={renderData}
          allowHover
          pagination={transactionsPagination}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div id="side-content" className="hide-scrollbar">
        <ReportSection ref={reportSectionRef} type="settlement-transaction" />
        <div className="filter-container d-flex flex-column justify-content-between bg-white shadow-sm mb-4">
          <form className="filter-form mt-0">
            <Input
              type="select"
              options={translateLabel(ORDER_TYPE, translate, true)}
              value={orderBy}
              onChange={onOrderByChange}
              legend={translate('order').toUpperCase()}
              name="type"
              disabled={isFetching}
            />
          </form>
        </div>
        <SideFilter isLoading={isFetching} onFilter={filterSearch} resetFilter={resetFilter}>
          <Input
            type="date"
            value={from}
            legend={translate('from').toUpperCase()}
            name="from"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              time: true,
              timePattern: ['h', 'm', 's'],
            }}
            value={start_date_time}
            legend={translate('time').toUpperCase()}
            name="start_date_time"
            onChange={onFilterChange}
            placeholder="hh:mm:ss"
          />
          <Input
            type="date"
            value={to}
            legend={translate('to').toUpperCase()}
            name="to"
            onChange={onFilterDateChange}
            placeholder="YYYY-MM-DD"
          />
          <Input
            type="cleave"
            cleaveOptions={{
              time: true,
              timePattern: ['h', 'm', 's'],
            }}
            value={end_date_time}
            legend={translate('time').toUpperCase()}
            name="end_date_time"
            onChange={onFilterChange}
            placeholder="HH:mm:ss"
          />
          {(isGatewayProvider(user.role) || isAgent(user.role)) && (
            <Input
              type="react-select-async"
              value={
                company_id !== ''
                  ? {
                      label: findCompanyName(),
                      value: company_id,
                    }
                  : {
                      label: translate('select_companies'),
                      value: '',
                    }
              }
              loadOptions={searchCompany}
              onChange={onCompanyNameSelect}
              legend={translate('company_name').toUpperCase()}
              name="company_name"
            />
          )}
          {(isGatewayProvider(user.role) || isAgent(user.role)) && (
            <Input
              type="text"
              legend={translate('company_id').toUpperCase()}
              value={company_id}
              name="company_id"
              onChange={onFilterChange}
              placeholder={translate('company_id')}
            />
          )}
          {!isAgent(user.role) && !isGatewayProvider(user.role) && (
            <Input
              type="cleave"
              cleaveOptions={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralIntegerScale: 7,
                numeralPositiveOnly: true,
              }}
              legend={translate('amount').toUpperCase()}
              value={amount}
              name="amount"
              onChange={onFilterChange}
              placeholder={translate('amount')}
            />
          )}
          <Input
            type="text"
            legend={translate('transaction_id').toUpperCase()}
            value={transaction_id}
            name="transaction_id"
            onChange={onFilterChange}
            placeholder={translate('transaction_id')}
          />
          <Input
            type="text"
            legend={translate('reference_id').toUpperCase()}
            value={reference_id}
            name="reference_id"
            onChange={onFilterChange}
            placeholder={translate('reference_id')}
          />
          <Input
            type="text"
            legend={translate('custom_id').toUpperCase()}
            value={custom_id}
            name="custom_id"
            onChange={onFilterChange}
            placeholder={translate('custom_id')}
          />
          {!isAgent(user.role) && !isGatewayProvider(user.role) && (
            <>
              <Input
                type="react-select"
                legend={translate('store').toUpperCase()}
                options={storeOptions}
                value={store}
                name="store"
                onChange={onFilterSelectChange('store')}
                placeholder={translate('store')}
                defaultLabel={translate('all')}
                menuPortalTarget={document.body}
              />
              <Input
                type="react-select"
                legend={translate('fixed_qr_code').toUpperCase()}
                options={fixedCodeOptions}
                value={fixed_code}
                name="fixed_code"
                onChange={onFilterSelectChange('fixed_code')}
                placeholder={translate('fixed_qr_code')}
                defaultLabel={translate('all')}
                menuPortalTarget={document.body}
              />
            </>
          )}
          {
            <Input
              type="react-select"
              legend={translate('transaction_status').toUpperCase()}
              options={translateLabel(TRANSACTION_STATUS_OPTIONS, translate)}
              value={status}
              name="status"
              onChange={onFilterSelectChange('status')}
              placeholder={translate('transaction_status')}
              defaultLabel={translate('all')}
              menuPortalTarget={document.body}
            />
          }
          {
            <Input
              type="react-select"
              legend={translate('wallet').toUpperCase()}
              options={translateLabel(WALLET_TYPE_OPTIONS, translate)}
              value={wallet}
              name="wallet"
              onChange={onFilterSelectChange('wallet')}
              placeholder={translate('wallet')}
              defaultLabel={translate('all')}
              menuPortalTarget={document.body}
            />
          }
          {features?.tokenization?.enable && (
            <>
              <Input
                type="text"
                legend={translate('payment_token_id').toUpperCase()}
                value={merchant_token_id}
                name="merchant_token_id"
                onChange={onFilterChange}
                placeholder={translate('payment_token_id')}
              />
              <Input
                type="text"
                legend={translate('payment_token_custom_id').toUpperCase()}
                value={merchant_token_custom_id}
                name="merchant_token_custom_id"
                onChange={onFilterChange}
                placeholder={translate('payment_token_custom_id')}
              />
            </>
          )}{' '}
          {!isTopUpRelatedFilterHidden && !isGatewayProvider(user.role) && !isAgent(user.role) && (
            <Input
              isMulti
              type="react-select-async"
              legend={translate('gateway').toUpperCase()}
              loadOptions={getCredentialOptions}
              value={gateways.map(valueToLabel)}
              name="gateways"
              onChange={setGateways}
              placeholder={translate('gateway')}
              // defaultLabel={translate('all_gateways')}
              menuPortalTarget={document.body}
            />
          )}
          {features?.top_up_balance?.enable && (
            <Input
              type="checkbox"
              name=""
              value={only_show_top_up_records}
              onChange={onFilterCheckBoxChange}
              focusRef={checkboxRef}>
              <div className="d-flex">
                <span style={{ color: '#9092a5' }}>{translate('only_show_top_up_records')}</span>
              </div>
            </Input>
          )}
        </SideFilter>
      </div>
      <TransactionModal
        isAgentOrGatewayProvider={isAgent(user.role) || isGatewayProvider(user.role)}
        reloadTransaction={getInitialData}
        isOpen={showTransactionDetailModal}
        setIsOpen={setShowTransactionDetailModal}
        transaction={selectedTransaction}
      />
      <CreateTransactionModal
        credentials={credentials}
        isTransactionModalOpen={openTransactionModal}
        onHide={() => setOpenTransactionModal(false)}
        onCompleteCreate={() => getInitialData()}
      />
    </>
  );
};
