import { Dispatch } from 'redux/types';
import {
  getCompanySalesAnalysis,
  getCompanySalesAnalysisV2,
  getGatewaySalesAnalysis,
  getUserSalesAnalysis,
} from 'services/API/Sales_Analysis';
import { GetCompanyAnalysisResponse } from 'services/API/Sales_Analysis/interface';
import { receiveAnalysis } from './DataAction';

export const getCompanyAnalysisAction = (company_id: string, data: object) =>
  handleReceiveAnalysis(() => getCompanySalesAnalysis(company_id, data));

export const getCompanyAnalysisV2Action = (company_id: string, data: object) =>
  handleReceiveAnalysis(() => getCompanySalesAnalysisV2(company_id, data));

export const getGatewayAnalysisAction = (gateway_id: string, data: object) =>
  handleReceiveAnalysis(() => getGatewaySalesAnalysis(gateway_id, data));

export const getUserAnalysisAction = (user_id: string, data: object) =>
  handleReceiveAnalysis(() => getUserSalesAnalysis(user_id, data));

const handleReceiveAnalysis =
  (fn: () => Promise<GetCompanyAnalysisResponse>) => async (dispatch: Dispatch) => {
    const res = await fn();
    if (res.success) {
      dispatch(receiveAnalysis(res));
    }
    return res;
  };
