import { getMe as apiGetMe } from 'actions/AuthActions';
import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { ANY_OBJECT } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { useSwal } from 'helpers/sweetalert';
import { Input } from 'components/custom/input';
import { validate } from './validator';
import { UpdateReserveBalanceProps } from 'services/API/ReserveBalance/interface';
import { updateReserveBalance } from 'services/API/ReserveBalance';
import { Spinner } from 'reactstrap';

const initialState = {
  reserve_balance: '',
} as UpdateReserveBalanceProps;

export const ReserveBalance = ({ location }: RouteComponentProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [isLoading, setIsLoading] = useState(false);

  const [reserveBalanceForm, setReserveBalanceForm] = useState(initialState);

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const handleReserveBalanceChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // save amount without comma
    setReserveBalanceForm({
      ...reserveBalanceForm,
      reserve_balance: (e.target as any).rawValue,
    });
  };

  const onReserveBalanceFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const { isValid, errors } = validate(reserveBalanceForm);
    setErrors(errors);
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const res = await updateReserveBalance(user?.company_id, reserveBalanceForm);
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('reserve_balance'),
        text: res?.message || 'Oops',
      });
      setIsLoading(false);
      return;
    }

    const res2 = await dispatch(apiGetMe('update'));
    if (!res2.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('reserve_balance'),
        text: res2.message ?? 'Oops',
      });
      setIsLoading(false);
      return;
    }

    await Swal.fire({
      icon: 'success',
      title: translate('reserve_balance'),
      text: translate('congratulations_reserve_balance_successfully_updated'),
    });
    setIsLoading(false);
  };

  useEffect(() => {
    const {
      data: { reserve_balance },
    } = user?.company?.company_balance || { data: { reserve_balance: '' } };
    const state = {
      reserve_balance,
    };
    setReserveBalanceForm(state ?? initialState);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader src="company-info" name="reserve_balance" />
          <div className="bg-white round-bottom-lg">
            <div className="text-left text-danger pt-4 px-4">
              <h4 className="fx-12">{translate('reserve_balance_form_warning')}</h4>
            </div>
            <div className="bg-white px-4 pb-4 round-bottom-lg">
              <div className="">
                <form onSubmit={onReserveBalanceFormSubmit}>
                  <Input
                    value={reserveBalanceForm.reserve_balance}
                    legend={translate('reserve_balance_amount').toUpperCase()}
                    name="reserve_balance"
                    type="cleave"
                    prefix="$"
                    cleaveOptions={{
                      numeral: true,
                      numeralDecimalScale: 2,
                      numeralIntegerScale: 7,
                      numeralPositiveOnly: true,
                    }}
                    onChange={handleReserveBalanceChange}
                    isRequired
                    error={translate(...(errors.reserve_balance ?? ''))}
                    disabled={isLoading}
                  />
                  <div className="text-end mt-3">
                    <button className="btn btn-primary" disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" /> : translate('submit')}
                    </button>
                  </div>
                </form>
              </div>
              <div className="mb-5 mb-md-0"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
