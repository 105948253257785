import {
  GET_ANALYSIS,
  LOGOUT,
  ON_SUPEROWNER_COMPANY_SELECT,
  RECEIVE_DATA,
  RECEIVE_LOGOS,
  SET_SELECTED_DOCUMENT,
  TABLE_CURRENT_PAGE,
} from 'actions/actionTypes';
import { actionProps } from 'redux/types';
import { PaginationProps } from 'services/API/common/Fetcher';
import { BankAccountProps } from 'services/API/Company/interface';
import { DeviceProps } from 'services/API/Device/interface';
import { CompanyQrCode } from 'services/API/FixedCode/interface';
import { CompanyPayByLinkCode } from 'services/API/PayByLinkCode/interface';
import { GetCompanyAnalysisResponse } from 'services/API/Sales_Analysis/interface';
import { SettlementProps } from 'services/API/Settlement/interface';
import { PreAuthTransactionProps } from 'services/API/PreAuthorize/interface';
import { StoreStateProps } from '../state/StoreState';
import {
  balanceTransactionsProps,
  documentProps,
  TaxiUserProps,
  TransactionProps,
  UserProps,
} from './dataProps';
import { MerchantTokenProps } from 'services/API/MerchantToken/interface';
import { TopUpBalanceHistoryProps } from 'services/API/TopUpBalance/interface';

const initialState: reducerState = {
  devices: {
    devices: [],
    devicesPagination: null,
  },
  stores: {
    stores: [],
    storesPagination: null,
  },
  users: {
    users: [],
    usersPagination: null,
  },
  transactions: {
    transactions: [],
    transactionsPagination: null,
  },
  accounts: {
    accounts: [],
    accountsPagination: null,
  },
  balanceTransactions: {
    balanceTransactions: [],
    balanceTransactionsPagination: null,
  },
  settlements: {
    settlements: [],
    settlementsPagination: null,
  },
  documents: {
    documents: [],
    documentsPagination: null,
    selectedDocument: {},
  },
  codes: {
    codes: [],
    codesPagination: null,
  },
  fixedCodes: {
    fixedCodes: [],
    fixedCodesPagination: null,
  },
  payByLinkCodes: {
    payByLinkCodes: [],
    payByLinkCodesPagination: null,
  },
  analysis: {
    sale: {} as GetCompanyAnalysisResponse,
  },
  preAuthTransactions: {
    preAuthTransactions: [],
    preAuthTransactionsPagination: null,
  },
  merchantTokens: {
    merchantTokens: [],
    merchantTokensPagination: null,
  },
  topUpBalanceHistory: {
    topUpBalanceHistory: [],
    topUpBalanceHistoryPagination: null,
  },
  logos: [],
};

export interface reducerState {
  devices: {
    devices: Array<DeviceProps>;
    devicesPagination: PaginationProps | null;
  };
  stores: {
    stores: Array<StoreStateProps>;
    storesPagination: PaginationProps | null;
  };
  users: {
    users: Array<UserProps | TaxiUserProps>;
    usersPagination: PaginationProps | null;
  };
  transactions: {
    transactions: Array<TransactionProps>;
    transactionsPagination: PaginationProps | null;
  };
  accounts: {
    accounts: Array<BankAccountProps>;
    accountsPagination: PaginationProps | null;
  };
  balanceTransactions: {
    balanceTransactions: Array<balanceTransactionsProps>;
    balanceTransactionsPagination: PaginationProps | null;
  };
  settlements: {
    settlements: Array<SettlementProps>;
    settlementsPagination: PaginationProps | null;
  };
  documents: {
    documents: Array<documentProps>;
    documentsPagination: PaginationProps | null;
    selectedDocument: Record<string, any>;
  };
  codes: {
    codes: Array<any>;
    codesPagination: PaginationProps | null;
  };
  fixedCodes: {
    fixedCodes: Array<CompanyQrCode>;
    fixedCodesPagination: PaginationProps | null;
  };
  payByLinkCodes: {
    payByLinkCodes: Array<CompanyPayByLinkCode>;
    payByLinkCodesPagination: PaginationProps | null;
  };
  analysis: {
    sale: GetCompanyAnalysisResponse;
  };
  preAuthTransactions: {
    preAuthTransactions: Array<PreAuthTransactionProps>;
    preAuthTransactionsPagination: PaginationProps | null;
  };
  merchantTokens: {
    merchantTokens: Array<MerchantTokenProps>;
    merchantTokensPagination: PaginationProps | null;
  };
  topUpBalanceHistory: {
    topUpBalanceHistory: Array<TopUpBalanceHistoryProps>;
    topUpBalanceHistoryPagination: PaginationProps | null;
  };
  logos: Array<documentProps>;
  [index: string]: object;
}

export interface extendedActionProps extends actionProps {
  dataType: string;
  tableType: string;
  logos: Array<documentProps>;
  page: number;
}

const dataReducer = (
  state: reducerState = initialState,
  { type, payload, dataType, tableType, logos, page }: extendedActionProps,
) => {
  switch (type) {
    case RECEIVE_DATA: {
      const { data, meta, ...rest } = payload;
      const pagination = meta.pagination;
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          [dataType]: data,
          [dataType + 'Pagination']: pagination,
          ...rest,
        },
      };
    }
    case GET_ANALYSIS:
      return {
        ...state,
        analysis: {
          sale: {
            ...payload,
            pagination: { ...(payload?.meta?.pagination || {}) },
          } as GetCompanyAnalysisResponse,
        },
      };
    case RECEIVE_LOGOS:
      return {
        ...state,
        logos: logos,
      };
    case TABLE_CURRENT_PAGE:
      return {
        ...state,
        [tableType]: {
          ...state[tableType],
          currentPage: page,
        },
      };
    case SET_SELECTED_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          selectedDocument: payload,
        },
      };
    case ON_SUPEROWNER_COMPANY_SELECT:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default dataReducer;
