import { UpdateReserveBalanceProps } from './interface';
import axios, { defaultRequestHandler, getToken, params } from '../common/Fetcher';

export const updateReserveBalance = (company_id: string, body: UpdateReserveBalanceProps) =>
  defaultRequestHandler(
    axios.put(`/balance/${company_id}/reserve-balance`, params(body), {
      headers: {
        authorization: getToken(),
      },
    }),
  );
