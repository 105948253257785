import { DetailRow } from 'components/custom/DetailRow';
import {
  PRECREATED,
  CAPTURED,
  AUTHORIZED,
  PARTIAL_CAPTURED,
  PENDING,
  TRANSACTION_STATUS_COLOR,
  REFUNDED,
} from 'constants/format';
import { operator, owner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import { PreAuthTransactionProps } from 'services/API/PreAuthorize/interface';
import { cancelPreAuthTransaction, getPreAuthTransactionDetails } from 'services/API/PreAuthorize';
import { useSwal } from 'helpers/sweetalert';
import { CustomTable } from 'components/custom/table/CustomTable';

interface DetailsTransactionModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  preAuth?: PreAuthTransactionProps;
  isAgentOrGatewayProvider: boolean;
  refreshData: () => void;
}

export const DetailsModal = ({
  isOpen,
  setIsOpen,
  preAuth,
  refreshData,
  isAgentOrGatewayProvider,
}: DetailsTransactionModalProps) => {
  if (!preAuth) {
    return <></>;
  }
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [allowCancel, setAllowCancel] = useState(false);
  const [isCancelProcessing, setIsCancelProcessing] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);
  const [detailsData, setDetailsData] = useState<PreAuthTransactionProps>(
    {} as PreAuthTransactionProps,
  );

  const printRef = useRef<HTMLDivElement>(null);

  const cancelAction = async () => {
    setIsCancelProcessing(true);
    const confirmResult = await Swal.fire({
      title: translate('pre_authorize'),
      text: `${translate('confirm_to_cancel')} (${translate('authorization_id')}:
        ${preAuth.authorization_id ?? ''})?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: translate('yes'),
      cancelButtonText: translate('no'),
    });
    if (confirmResult.isConfirmed) {
      const id = preAuth.id;
      const res = await cancelPreAuthTransaction(id);
      setIsCancelProcessing(false);
      if (!res.success) {
        Swal.fire({
          title: translate('pre_authorize_details'),
          text: res?.message || 'Oops',
          icon: 'error',
        });
        return;
      }

      Swal.fire({
        icon: 'success',
        title: translate('pre_authorize'),
        text: translate('congratulations_authorization_successfully_cancelled'),
      });
      refreshDetails();
    }
    setIsCancelProcessing(false);
  };

  const getDetails = async () => {
    setIsDetailsLoading(true);
    const data = {
      include: ['store', 'transactions'],
    };
    const auth_id = preAuth.id;
    try {
      const res = await getPreAuthTransactionDetails(user?.company_id, auth_id, data);
      if (res.success) {
        setDetailsData(res.data);
      } else {
        throw new Error(res.message);
      }
    } catch (error: any) {
      Swal.fire({
        title: translate('pre_authorize_details'),
        text: error.message || 'Oops',
        icon: 'error',
      });
    } finally {
      setIsDetailsLoading(false);
    }
  };

  const isAllowCancel = (data: any) => {
    return data.status === AUTHORIZED.toLowerCase();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const closeAll = () => {
    setIsOpen(false);
  };

  const isCaptured = (data: any) => {
    return data.status === CAPTURED.toLowerCase() || data.status === PARTIAL_CAPTURED.toLowerCase();
  };

  const viewTransaction = (id: string) => {
    const url = `/transaction?transaction_id=${id}`;
    window.open(url, '_blank');
  };

  const refreshDetails = () => {
    setIsOpen(false); // close dialog
    refreshData();
  };

  useEffect(() => {
    if (isOpen) {
      getDetails();
    } else {
      setDetailsData({} as PreAuthTransactionProps);
    }
  }, [isOpen]);

  useEffect(() => {
    if (Object.keys(detailsData).length > 0) {
      setIsDetailsLoading(false);
      setAllowCancel(isAllowCancel(detailsData));
    }
  }, [detailsData]);

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0"></th>
        <th className="border-end border-top-0">{translate('transaction_id')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('tip_amount')}</th>
        <th className="border-end border-top-0">{translate('status')}</th>
        <th className="border-end border-top-0">{translate('created_at')}</th>
      </tr>
    );
  };

  const transactionsData = () => {
    return detailsData.transactions?.map((transaction: any, index: any) => {
      const { amount, tips, currency, status, transaction_id, created_at } = transaction;

      return (
        <tr key={index} className="cursor-pointer">
          <td>
            <button
              className="btn btn-success btn-sm"
              onClick={() => viewTransaction(transaction.transaction_id)}>
              {translate('view')}
            </button>
          </td>
          <td>
            <span>{transaction_id}</span>
          </td>
          <td>
            <div className="amount" data-currency={currency}>
              {amount}&nbsp;
            </div>
          </td>
          <td>
            <div className="amount" data-currency={currency}>
              {tips}&nbsp;
            </div>
          </td>
          <td>
            <div className={`text-${TRANSACTION_STATUS_COLOR[status]} px-2 text-uppercase`}>
              {translate(status)}
            </div>
            {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
              <div className="amount text-light" data-currency={currency}>
                {transaction.refunded}&nbsp;
              </div>
            )}
          </td>
          <td>{created_at}</td>
        </tr>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{translate('pre_authorize_details')}</ModalHeader>
      <ModalBody>
        <div ref={printRef} className="p-3">
          <div className="transaction-content">
            <h4>
              <div className="row">
                <div className="col-12">
                  <DetailRow
                    name={translate('authorization_id')}
                    value={preAuth.authorization_id ?? ''}
                    nameWidth="col-3"
                  />
                  <DetailRow
                    name={translate('created_at')}
                    value={preAuth.created_at ?? ''}
                    nameWidth="col-3"
                  />
                  <DetailRow
                    name={translate('store')}
                    value={preAuth.store?.name ?? ''}
                    nameWidth="col-3"
                  />
                </div>
              </div>
            </h4>
          </div>
          <hr className="dash-border" />
          <div className="transaction-content">
            {isDetailsLoading ? (
              <>
                <div style={{ textAlign: 'left', margin: '10px' }}>
                  <Spinner size="sm" />
                </div>
              </>
            ) : (
              <>
                <h4>
                  <DetailRow
                    name={translate('gateway')}
                    value={detailsData.gateway_sub_name ?? ''}
                    nameWidth="col-3"
                  />
                  <DetailRow
                    name={translate('status')}
                    value={translate(`preAuthStatus.${detailsData.status}`)}
                    nameWidth="col-3"
                  />
                  <DetailRow
                    name={translate('amount')}
                    value={`${detailsData.amount} ${detailsData.currency}` ?? ''}
                    nameWidth="col-3"
                  />
                  <DetailRow
                    name={translate('pre_auth_captured')}
                    value={`${detailsData.captured} ${detailsData.currency}` ?? ''}
                    nameWidth="col-3"
                  />
                  <DetailRow
                    name={translate('remaining_amount')}
                    value={`${detailsData.remaining_amount} ${detailsData.currency}` ?? ''}
                    nameWidth="col-3"
                  />
                </h4>
                {detailsData.transactions && (
                  <>
                    <hr className="dash-border" />
                    <div>
                      <h5>{translate('transaction_details')}</h5>
                      <CustomTable
                        renderHeading={() => tableHeader()}
                        renderData={transactionsData}
                        totalColumn={5}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="text-center flex-fill">
          {(user.role === owner || user.role === operator) && allowCancel && !isDetailsLoading && (
            <div className="d-grid">
              <button
                className="btn btn-danger"
                onClick={() => cancelAction()}
                disabled={isCancelProcessing}>
                {isCancelProcessing ? <Spinner size="sm" /> : `${translate('cancel_pre_auth')}`}
              </button>
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
